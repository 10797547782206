<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view :style="{ height: height, 'font-family': font }" />
    </transition>
  </v-app>
</template>

<script>/* 
import axios from "axios"; */

export default {
  name: "App",
  data() {
/*     const url = "/k/v1/apis.json";
    axios
      .get(url, null, {
        headers: this.$config.kintone_headers_estimate,
      })
      .then((res) => {
        console.log("res");
        console.log(res);
      })
      .catch((e) => {
        console.log("e");
        console.log(e);
      }); */
    return {
      touchCount: 0,
      height: `${window.innerHeight}px`,
      font:
        "MS PGothic, Arial,YuGothic,Yu Gothic,Hiragino Kaku Gothic ProN,ヒラギノ角ゴ ProN W3,メイリオ, Meiryo,ＭＳ ゴシック,sans-serif",
    };
  },
  methods: {
    handleResize() {
      this.height = `${window.innerHeight}px`;
    },
    touchHandler(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
      if (!this.touchCount) {
        // タップの回数を+1
        ++this.touchCount;
        // 500ミリ秒以内に2回目のタップがされればダブルタップと判定
        setTimeout(function() {
          this.touchCount = 0;
        }, 500);

        // ダブルタップ
      } else {
        // 拡大をさせない
        event.preventDefault();
        // 処理を記述
        // 回数をリセット
        this.touchCount = 0;
      }
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("touchstart", this.touchHandler, {passive: false});
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("touchstart", this.touchHandler, {passive: false});
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
