const pageLists = [
  {
    path: '1',
    name: 'Page1_shigyo',
    component:() => import("../components/shigyo/Page1.vue"),
    meta: {
      type: "shigyo",
      index: 1
    }
  },
  {
    path: '2',
    name: 'Page2_shigyo',
    component:() => import("../components/shigyo/Page2.vue"),
    meta: {
      type: "shigyo",
      index: 2
    }
  },
  {
    path: '3',
    name: 'Page3_shigyo',
    component:() => import("../components/shigyo/Page3.vue"),
    meta: {
      type: "shigyo",
      index: 3
    }
  },
  {
    path: '4',
    name: 'Page4_shigyo',
    component:() => import("../components/shigyo/Page4.vue"),
    meta: {
      type: "shigyo",
      index: 4
    }
  },
  {
    path: '5',
    name: 'Page5_shigyo',
    component:() => import("../components/shigyo/Page5.vue"),
    meta: {
      type: "shigyo",
      index: 5
    }
  },
  {
    path: '6',
    name: 'Page6_shigyo',
    component:() => import("../components/shigyo/Page6.vue"),
    meta: {
      type: "shigyo",
      index: 6
    }
  },
  {
    path: '7',
    name: 'Page7_shigyo',
    component:() => import("../components/shigyo/Page7.vue"),
    meta: {
      type: "shigyo",
      index: 7
    }
  },
  {
    path: '8',
    name: 'Page8_shigyo',
    component:() => import("../components/shigyo/Page8.vue"),
    meta: {
      type: "shigyo",
      index: 8
    }
  },
  {
    path: '9',
    name: 'Page9_shigyo',
    component:() => import("../components/shigyo/Page9.vue"),
    meta: {
      type: "shigyo",
      index: 9
    }
  },
  {
    path: '10',
    name: 'Page10_shigyo',
    component:() => import("../components/shigyo/Page10.vue"),
    meta: {
      type: "shigyo",
      index: 10
    }
  },
  {
    path: '11',
    name: 'Page11_shigyo',
    component:() => import("../components/shigyo/Page11.vue"),
    meta: {
      type: "shigyo",
      index: 11
    }
  },
  {
    path: '12',
    name: 'Page12_shigyo',
    component:() => import("../components/shigyo/Page12.vue"),
    meta: {
      type: "shigyo",
      index: 12
    }
  },
  {
    path: '13',
    name: 'Page13_shigyo',
    component:() => import("../components/shigyo/Page13.vue"),
    meta: {
      type: "shigyo",
      index: 13
    }
  },
  {
    path: '14',
    name: 'Page14_shigyo',
    component:() => import("../components/shigyo/Page14.vue"),
    meta: {
      type: "shigyo",
      index: 14
    }
  },
  {
    path: '15',
    name: 'Page15_shigyo',
    component:() => import("../components/shigyo/Page15.vue"),
    meta: {
      type: "shigyo",
      index: 15
    }
  }
]

export default pageLists