import Vue from 'vue'
import App from './App.vue'
import "./App.scss"
import router from './router'
import config from "./config.js"
import vuetify from './plugins/vuetify'
import i18n from './library/i18n'
import { initializeApp } from "firebase/app";


initializeApp(config.firebaseConfig);

Vue.config.productionTip = false
Vue.prototype.$config = config

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
