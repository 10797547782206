export default {
  "agree": "はい",
  "disagree": "いいえ",
  "title": "アキコン",
  "switch_shigyo": "士業用に切り替える",
  "switch_akiya": "アキコンに切り替える",
  "next_btn_label": "すすむ >",
  "previos_btn_label": "< もどる",
  "confirm_text": "本当に終了して問題ございませんか？",
  "toTop_btn_label": "トップページ",
  "subtitle": "Akiya Consultation System",
  "login_description_message": "アンケートを行う店舗のアカウントでログインしてください",
  "login_email_title": "e-mail",
  "login_password_title": "password",
  "login_btn": "ログイン",
  "login_caution_message": "e-mail、パスワードを忘れてしまった場合には、＊＊＊＊にお問い合わせください",
  "login_error_message": "e-mailまたはパスワードが間違ってます",
  "logout": "ログアウト",
  "logout_confirm_text": "本当にログアウトしますか？",
  "home_title_akiya": "～ 住まなくなったお宅のお悩み相談会 ～",
  "home_title_shigyo": "～ 引き継ぐ住まいの相談窓口 ～",
  "home_description_akiya": "不動産、片付け、仏壇、お墓、登記手続き、住まいに関する様々なお悩みを解決します！",
  "home_description_shigyo": "不動産、片付け、仏壇、お墓、登記手続き、住まいに関する様々なお悩みを解決します！",
  "home_btn": "案内を開始する",
  "consult_title": "空き家相談フォーム",
  "consult_desc": "下記フォームからお客様の空き家に関する情報をご登録ください。",
  "agree_button": "同意して相談する",
  "agree_privacy_title": "個人情報の取得について",
  "agree_privacy_desc": "次のページに進んで頂くにあたり、下記に記載されている内容に同意していただく必要がございます。\n\n（利用目的）\n当社は、収集した個人情報について、以下の目的のために利用いたします。\n\n ①　お客様の空き家コンサルティングの前提として公開情報である不動産登記簿謄本（公開情報）を取得し、照合すること、その他不動産の調査を行うこと\n ※入力情報と登記情報が照合できない不動産につきましては、簡易調査は出来かねますので、ご了承ください。\n\n  ②　相談・お問い合わせへの回答のため\n\n  ③　空き家見解書の送付及びお客様へのコンサルティングのため\n\n     合計15問でございます。5分ほどお付き合い下さい。",
  "agree_privacy_accept": "同意する",
  "send_message01_1": "アンケートのご入力ありがとうございました。",
  "send_message01_2": "あなたの空き家相談を承りました。",
  "send_message02_1": "ご入力ありがとうございました。",
  "send_message02_2": "管理、お片付けのお見積もり依頼、承りました。",
  "send_message02_3": "3営業日以内に、ご連絡させて頂きます。",
  "page1_desc1": "空き家相談システム「アキコン」",
  "page1_desc2": "住まなくなった実家（空き家）のお悩み相談\n空き家を放置すると大変なことに！そうなる前に対策を",
  "page1_desc3": "こんにちは。空き家カウンセラーのアッキーです。私が、皆さんの空き家のこれからを解説していきます。最後に無料相談コーナーもあるよ。",
  "page1_desc4": "利用料は一切かかりません。\nお気軽にお試しください。",
  "page2_desc1": "空き家イメージのすり合わせ",
  "page2_desc2": "一般的に空き家＝ボロボロ、壊れかけの住宅をイメージします。\n実際は、人が住まなくなったところから空き家です",
  "page2_desc3": "すべて「空き家」と呼ばれます",
  "page3_desc1": "空き家を保有したら何をすれば良いの？",
  "page3_desc2": "空き家を保有した理由で最も多いのは親からの「相続」です",
  "page3_desc3": "その他の理由として、親が施設に入居するタイミングや、移住や転勤などがあります。",
  "page4_desc1": "多くの所有者が様々な理由から放置しがち",
  "page4_desc2": "多くの空き家が「売ること」「貸すこと」ができない状態にある",
  "page5_desc1": "面倒だからと放置すると、、",
  "page6_desc1": "空き家の放置は、所有者ご本人の利益を得られる機会を失い、近隣住民など当事者以外の誰かにも費用の負担や不利益を及ぼすことになります。",
  "page7_desc1": "全国で空き家に関する様々な事例が報告されています。",
  "page7_desc2": "自然災害の多い日本で、自分の家は大丈夫ということはありません。",
  "page8_desc1": "この様なことが全国で起こっています",
  "page8_desc2": "あの時売却しておけば良かった...と後悔",
  "page9_desc1": "大切な資産の価値を損ねないためにも適切な\n対処が求められます",
  "page10_desc1": "ではどうすれば良いのか？",
  "page10_desc2": "暫く利活用が難しい",
  "page10_desc3": "管理する",
  "page10_desc4": "すぐに使うことができる",
  "page10_desc5": "売るor貸す",
  "page10_desc6": "or自分で使う",
  "page10_desc7": "どうするか決まっていない",
  "page10_desc8": "専門家に相談する",
  "page11_desc1": "どの選択肢であってもやらなくてはいけないこと",
  "page11_desc2": "問題を先送りせずに、早めの対応が必要です",
  "page12_desc1": "アキコンから、次のサービスが利用可能です",
  "page12_desc2": "①空き家コンサルタントの無料相談",
  "page12_desc3": "②片付けの見積依頼",
  "page12_desc4": "③空き家管理の見積もり依頼",
  "page12_desc5": "サービスの詳細は次のページをご覧ください",
  "page13_desc1": "①空き家コンサルタントの無料相談",
  "page13_desc2": "特徴：　空き家の専門家が親身にアドバイスします",
  "page13_desc3": "利用するメリット",
  "page13_desc4": "・3営業日以内に周辺相場がわかる",
  "page13_desc5": "・具体的な選択肢を決める事ができる",
  "page13_desc6": "・全国対応なので現地まで行かなくても問題を解決できる",
  "page14_desc1": "②片付けの見積依頼",
  "page14_desc2": "③空き家管理の見積もり依頼",
  "page14_desc3": "特徴：　課題解決に向けて速やかに見積もりをご用意いたします",
  "page14_desc4": "利用するメリット",
  "page14_desc5": "・解決までの費用が明確になる",
  "page14_desc6": "・遠方の立会いが難しい場合でも依頼できる(諸条件あり)",
  "page15_desc1": "お金をかけずに、すぐできること",
  "page15_desc2": "空き家の現状を具体的に知ることで、次のアクションが明確化します",
  "page16_desc1": "問題を先送りせず、速やかに対策することをお勧めします。",
  "page17_desc1": "ご拝読ありがとうございました。",
  "page17_consult": "相談する",
  "page17_contact": "片付けの見積もりを\n依頼する",
  "page17_exit": "終了する",
  "page17_desc3": "見積もりを依頼する",
  "form_require": "(必須)",
  "form_many": "※複数選択可",
  "form_send": "送信",
  "form_send_succsess": "送信完了",
  "form_send_error": "送信に失敗しました",
  "form_placeholder": "ご記入ください",
  "form_postal_code": "郵便番号検索",
  "form_search_label": "検索",
  "form_postal": "〒　",
  "form_address": "住所",
  "form_name": "氏名",
  "form_Q1": "Q1.空き家の所在地をお教えください。",
  "form_Q2": "Q2 空き家を所有していますか？",
  "form_Q3": "Q3 取得した経緯を教えてください。",
  "form_Q4": "Q4 建物を利用しなくなった理由をお聞かせください。",
  "form_Q5": "Q5 空き家にしている期間を教えてください。",
  "form_Q6": "Q6 空き家の築年数は何年ですか？",
  "form_Q7": "Q7 現在の利用状況についてお聞かせください。",
  "form_Q8": "Q8 維持管理の頻度について教えてください。",
  "form_Q9": "Q9 誰が行なっていますか？",
  "form_Q10": "Q10 将来の空き家の選択肢を決めていますか？",
  "form_Q11": "Q11 不動産の名義人に関するご質問",
  "form_Q11_form1": "・不動産名義人氏名",
  "form_Q11_form2": "・名義人と相談者の関係",
  "form_Q11_desc": "※所有地の登記簿上の名義人様のお名前をご入力ください",
  "form_Q12": "Q12 所有地に関するご質問",
  "form_Q12_form1": "・所有地の住居表示（住所)",
  "form_Q12_form2": "・所有地の地番を教えてください",
  "form_Q12_form3": "・土地と建物の名義人が異なりますか？",
  "form_Q12_form2_desc": "※家屋が建つ土地の地番　※固定資産税の納付書などを確認し、正確にご入力ください",
  "form_Q12_form3_desc": "※共有名義や借地など\n例) 土地: 借地/近所のお寺の名義　建物: 所有/現状空き家",
  "form_Q13": "Q13 お客様に関するご質問",
  "form_contact_name": "お名前",
  "form_contact_kata": "フリガナ",
  "form_contact_phone": "電話番号(－無し)",
  "form_contact_email": "メールアドレス",
  "form_contact_region": "お住まいの地域",
  "form_contact_age": "年齢",
  "form_contact_sex": "性別",
  "form_contact_occupation": "ご職業",
  "form_contact_forwarding":"※見解書（レポート）を上記メールアドレス以外への送付を希望する場合には、ご記入下さい。",
  "estimate_title_clean": "お片付けに関するお問い合わせ",
  "estimate_title_manage": "管理に関するお問い合わせ",
  "estimate_name": "お名前",  
  "estimate_name_placeholder": "空家 太郎",
  "estimate_kata": "フリガナ",  
  "estimate_kata_placeholder": "アキヤ タロウ",  
  "estimate_address": "所有地の住所",
  "estimate_address_placeholder":"○○県△△市123-4",
  "estimate_phone": "電話番号",
  "estimate_phone_placeholder": "09012345678",
  "estimate_weekday": "ご連絡希望の曜日",
  "estimate_time": "ご連絡希望の時間帯",
  "estimate_details": "お問い合わせ内容",
  "name_placeholder": "空家 太郎",
  "kata_placeholder": "アキヤ タロウ",
  "email_placeholder": "example@example.com",
  "phone_placeholder": "0801234567",
  "forwarding_name_placeholder": "送付先宛名",
  "select_placeholder": "ご選択ください",
  "validation_required": "※項目が未入力です",
  "validation_telFormat": "※電話番号の形式が正しくありません",
  "validation_emailFormat": "※メールアドレスの形式が正しくありません",
  "validate_postCode": "※入力内容に誤りがあります",
  "post_label": "ご入力された内容で送信してもよろしいでしょうか？",
  "error_label": "入力エラー"
}