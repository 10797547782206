import Vue from "vue"
import VueI18n from 'vue-i18n'
import ja_JP from "../lang/ja_JP"

Vue.use(VueI18n)

var messages = {
  "ja_JP" : ja_JP,
}

const i18n = new VueI18n({
  locale: "ja_JP",
  messages: messages
})

export default i18n
