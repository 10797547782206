import pageLists_akiya from "./pageLists_akiya"
import pageLists_shigyo from "./pageLists_shigyo"
import formLists from "./formLists"

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Home_Login',
    component:() => import("../views/Home_login.vue"),
  },
  {
    path: '/akiya',
    name: 'Home_akiya',
    component:() => import("../views/Home_akiya.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/shigyo',
    name: 'Home_shigyo',
    component:() => import("../views/Home_shigyo.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/akiya/page',
    name: 'Page_akiya',
    component:() => import("../views/Page_akiya.vue"),
    meta: { requiresAuth: true },
    children: pageLists_akiya
  },
  {
    path: '/shigyo/page',
    name: 'Page_shigyo',
    component:() => import("../views/Page_shigyo.vue"),
    meta: { requiresAuth: true },
    children: pageLists_shigyo
  },
  {
    path: '/consult',
    name: 'consult',
    component:() => import("../views/Consult.vue"),
    meta: { requiresAuth: true },
    children: formLists
  },
  {
    path: '/agree',
    name: 'agree',
    component:() => import("../views/Agree.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/estimate/:type',
    name: 'estimate',
    component:() => import("../views/Estimate.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/send01',
    name: 'send01',
    component:() => import("../views/Send01.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/send02',
    name: 'send02',
    component:() => import("../views/Send02.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    name: 'NotFound',
    component:() => import("../views/NotFound.vue"),
  }
]

export default routes